import './LoginForm.scss';
import React from 'react';
import { useLoginForm } from './useLoginForm';
import logo from 'assets/logos/logo.png';

export const LoginForm: React.FC = () => {
    const { formik, error } = useLoginForm();

    return (
        <form onSubmit={formik.handleSubmit} className="login-form">
            <img src={logo} alt="" />
            <div className="inputs">
                <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Adresse email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                />

                <input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Mot de passe"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                />
            </div>

            <button type="submit">Se connecter</button>

            {error && <p className="error-message">{error}</p>}
        </form>
    );
};