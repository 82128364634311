import './DefaultView.scss';
import React, { useEffect } from 'react';
import { Dashboard } from 'components';

export type DefaultViewProps = {
    archive: boolean;
};

export const DefaultView: React.FC<DefaultViewProps> = ({ archive }) => {

    useEffect(() => {
    }, [archive]);
    return (
        <div className="default-view">
            <Dashboard archive={archive}/>
        </div>
    );
};
