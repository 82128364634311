import './ProspectRecap.scss';

import { EditingProspect, ProjetClient, TravauxProgrammes } from 'features/prospects';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateQuotation, validateQuotation } from 'api/prospects';

type Props = React.PropsWithChildren & {
    prospect: EditingProspect;
};

export default function ProspectRecap({
                                          prospect: {
                                              infos: prospect,
                                              questionary,
                                              projetClient,
                                              travaux
                                          }
                                      }: Props) {

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    async function sendMail() {
        await validateQuotation({
            ...prospect,
            call_date: prospect.call_date ? prospect.call_date.substring(0, 16) : null,
            questionary,
            travaux,
            projetClient
        }, prospect.id);
        navigate('/');
    }

    return (
        <>
            {prospect && <div className="quick-recap">
                <div className="data">
                    <div>
                        Abri recherché: <span className="value">{prospect.abri}</span>
                    </div>
                    <div>
                        Type d'abri recherché: <span className="value">{prospect.abri_type ?? 'Je ne sais pas'}</span>
                    </div>
                    <div>
                        Nombre de pentes souhaité: <span className="value">{prospect.pentes}</span>
                    </div>
                    <div>
                        Hauteur: <span className="value">{prospect.hauteur}</span>
                    </div>
                    <div>
                        Largeur: <span className="value">{prospect.largeur}</span>
                    </div>
                    <div>
                        Profondeur: <span className="value">{prospect.profondeur}</span>
                    </div>
                    <div>
                        Date:
                        <span className="value">
                            {DateTime.local(+prospect.date?.substring(0, 4), +prospect.date?.substring(5, 7)).toLocaleString({
                                month: 'long',
                                year: 'numeric'
                            })}
                        </span>
                    </div>
                    <div>
                        Demande d'installation: <span className="value">{prospect.pose}</span>
                    </div>
                </div>
            </div>}

            <div>
                Information: <span className="value">{prospect.infos}</span>
            </div>

            {prospect && <div className="prospect-infos">
                <div>
                    Nom: <span className="value">{prospect.lastname}</span>
                </div>
                <div>
                    Prénom: <span className="value">{prospect.firstname}</span>
                </div>
                <div>
                    E-mail: <span className="value">{prospect.email}</span>
                </div>
                <div>
                    Téléphone: <span className="value">{prospect.phone}</span>
                </div>
                <div>
                    Adresse: <span className="value">{prospect.adress}</span>
                </div>
                <div>
                    Code postal: <span className="value">{prospect.zipcode}</span>
                </div>
                <div>
                    Ville: <span className="value">{prospect.city}</span>
                </div>
            </div>}

            {questionary && <div className="questionary">
                {questionary.permis && <div className="question-block">
                    <div className="question">
                        Avez-vous déjà soumis une demande à la mairie concernant votre projet (permis de construire,
                        déclaration préalable
                        de travaux) ?
                    </div>
                    <div className="answer">{questionary.permis}</div>
                </div>}
                {questionary.date && <div className="question-block">
                    <div className="question">Quand prévoyez-vous de commencer votre projet ?</div>
                    <div className="answer">{questionary?.date}</div>
                </div>}
                {questionary.priorite && <div className="question-block">
                    <div className="question">
                        Y a-t-il d’autres projets que vous envisagez qui pourraient être prioritaires par rapport à
                        celui-ci ?
                    </div>
                    <div className="answer">{questionary?.priorite}</div>
                </div>}
                {questionary.etude && <div className="question-block">
                    <div className="question">Avons-nous déjà effectué une étude préliminaire de votre projet ?</div>
                    <div className="answer">{questionary?.etude}</div>
                </div>}
                {questionary.installation && <div className="question-block">
                    <div className="question">
                        Cherchez-vous uniquement à acquérir le matériel nécessaire ou souhaitez-vous également que nous
                        nous chargions de
                        l’installation ?
                    </div>
                    <div className="answer">{questionary?.installation}</div>
                </div>}
                {questionary.decouverte && <div className="question-block">
                    <div className="question">Comment avez-vous entendu parler de nous ?</div>
                    <div className="answer">{questionary?.decouverte}</div>
                </div>}
                {questionary.aide && <div className="question-block">
                    <div className="question">Comptez-vous bénéficier d’une aide financière pour ce projet ?</div>
                    <div className="answer">{questionary?.aide}</div>
                </div>}
                {questionary.budget && <div className="question-block">
                    <div className="question">Quel est le budget que vous avez prévu pour ce projet ?</div>
                    <div className="answer">{questionary?.budget}</div>
                </div>}
                {questionary.raison && <div className="question-block">
                    <div className="question">Quelle est la raison principale pour laquelle vous souhaitez construire
                        cet abri ?
                    </div>
                    <div className="answer">{questionary?.raison}</div>
                </div>}
                {questionary.technique && <div className="question-block">
                    <div className="question">Questions technique :</div>
                    <div className="answer">{questionary?.technique}</div>
                </div>}
            </div>}

            <div className="techincal-sheet">
                {projetClient &&
                    <section>
                        <div className={'title'}>Projet du client</div>
                        <div className="inputs">
                            {(projetClient && Object.entries(projetClient)?.every(([key, value]) => value === '')) ? 'Aucune saisie' : ''}
                            {projetClient?.pergola && <label>
                                Pergola:{' '}
                                <div>
                                    {projetClient?.pergola}
                                </div>
                            </label>}
                            {projetClient?.essence_bois && <label>
                                Essence de bois:{' '}
                                <div
                                >
                                    {projetClient?.essence_bois}
                                </div>
                            </label>}
                            {projetClient?.traitement_par_situation && <label>
                                Traitement de bois:{' '}
                                <div
                                >
                                    {projetClient?.traitement_par_situation}
                                </div>
                            </label>}
                            {projetClient?.finition_liens && <label>
                                Finition liens et contrefiche:{' '}
                                <div
                                >{projetClient?.finition_liens}
                                </div>
                            </label>}
                            {projetClient?.hauteur_sous_sabliere && <label>
                                Hauteur sous sablière (ml):{' '}
                                {projetClient?.hauteur_sous_sabliere}
                            </label>}
                            {projetClient?.nature_couverture && <label>
                                Nature de la couverture:{' '}
                                <div
                                >
                                    {projetClient?.nature_couverture}
                                </div>
                            </label>}
                            {projetClient?.bardage_bois && <label>
                                Bardage bois:{' '}
                                <div

                                >
                                    {projetClient?.bardage_bois}
                                </div>
                            </label>}
                            {projetClient?.bardage_essence && <label>
                                Bardage essence:{' '}
                                <div

                                >
                                    {projetClient?.bardage_essence}
                                </div>
                            </label>}
                            {projetClient?.garde_corps && <label>
                                Garde corps:{' '}
                                <div>
                                    {projetClient?.garde_corps}
                                </div>
                            </label>}
                            {projetClient?.garde_corps_essence && <label>
                                Garde corps essence:{' '}
                                <div>
                                    {projetClient?.garde_corps_essence}
                                </div>
                            </label>}
                            {projetClient?.terrasse_bois && <label>
                                Terrasse bois:{' '}
                                <div>
                                    {projetClient?.terrasse_bois}
                                </div>
                            </label>}
                        </div>
                    </section>
                }
                {travaux &&
                    <section>
                        <div className={'title'}>Travaux préparatoires</div>
                        <div className="inputs">

                            {travaux && Object.entries(travaux)?.every(([key, value]) => value === '') ? 'Aucune saisie' : ''}
                            {travaux?.plots && <label>
                                Plots en béton:{' '}
                                <div>
                                    {travaux?.plots}
                                </div>
                            </label>}
                            {travaux?.dalle_en_beton && <label>
                                Dalle en béton:{' '}
                                <div>
                                    {travaux?.dalle_en_beton}
                                </div>
                            </label>}
                            {travaux?.demontage_existant && <label>
                                Démontage existant:{' '}
                                <div>
                                    {travaux?.demontage_existant}
                                </div>
                            </label>}
                            {travaux?.decaissement_du_terrain && <label>
                                Décaissement du terrain:{' '}
                                <div>
                                    {travaux?.decaissement_du_terrain}
                                </div>
                            </label>}
                            {travaux?.elevation_de_murets && <label>
                                Élévation de murets:{' '}
                                <div>
                                    {travaux?.elevation_de_murets}
                                </div>
                            </label>}
                            {travaux?.coupe_vegetale && <label>
                                Coupe végétale:{' '}
                                <div>
                                    {travaux?.coupe_vegetale}
                                </div>
                            </label>}
                            {travaux?.photos && <label>
                                Photos:{' '}
                                <div>{travaux?.photos}</div>
                            </label>}
                        </div>
                    </section>
                }
            </div>

            <div className="ctas">
                <div className="button alt" onClick={sendMail}>Envoyer par mail</div>
            </div>
        </>
    );
}
