import React, { ReactElement } from 'react';

import './Table.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { FiArchive, FiEdit } from 'react-icons/fi';
import { IconContext } from 'react-icons';
import { setProspectArchived } from 'api/prospects';
import { Quotation } from 'components/Dashboard/useDashboardVm';
import { FaRegFilePdf } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { MdUnarchive } from 'react-icons/md';

type Props = {
    rows: any[];
    columns: TableColumn[];
};

export type TableColumn = {
    field: string;
    label?: string;
    filter?: string;
    cellDataType?: string;
    rowDrag?: boolean;
    format?: (value: any) => string;
    default?: any;
    customElement?: (value: any, row: Quotation) => JSX.Element;
};

export const Table = ({ rows, columns }: Props) => {
    const columnCount = columns.length;
    const gridTemplateColumns = 'repeat(' + columnCount + ', minmax(min(100%, 75px), 1fr)) repeat(3, 96px)';
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const isArchived = pathname.includes('archives');

    function editProspect(prospect: any) {
        navigate('/prospect/' + prospect.id);
    }

    async function archiveProspect(prospect: Quotation) {
        Swal.fire({
            title: `${isArchived ? "Désarchiver" : "Archiver"} ${prospect.firstname} ${prospect.lastname}?`,
            text: `Vous allez ${isArchived ? "désarchiver" : "archiver"} ce prospect`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Oui, ${isArchived ? "le désarchiver" : "l'archiver"}`,
            cancelButtonText: "Annuler"
        }).then(async (result: any) => {
            if (result.isConfirmed) {

                await setProspectArchived(prospect.id, !prospect.archived);
                await Swal.fire({
                    title: isArchived ? 'Désarchivé!' : 'Archivé!',
                    text: `${prospect.firstname} ${prospect.lastname} a bien été ${isArchived ? "désarchivé(e)" : "archivé(e)"}!`,
                    icon: 'success'
                });
                window.location.reload()
            }
        });
    }

    return (
        <div className="table">
            <div className="table-header" style={{ gridTemplateColumns }}>
                {columns.map((column, index) => {
                    return (
                        <div className="table-cell" key={'table-header-' + index}>
                            {column.label ?? column.field}
                        </div>
                    );
                })}
                <div className="table-cell">Récap</div>
                <div className="table-cell">Edition</div>
                <div className="table-cell">{isArchived ? "Désarchiver" : "Archiver"}</div>
            </div>

            <div className="table-body">
                {rows.map((row, index) => {
                    return (
                        <div className="row" key={'table-body-row-' + row.id} style={{ gridTemplateColumns }}>
                            {columns.map((column) => {
                                let value = row[column.field];

                                if (column.format) value = column.format(value);

                                if (!value && column.default) value = column.default;

                                return (
                                    <div
                                        className={`cell field-${column.field} ${column.format ? 'format' : ''}`}
                                        key={'table-body-row-' + row.id + '-field-' + column.field + (column.format ? '-formatted' : '')}
                                    >
                                        {column.customElement ? column.customElement(value, row) : value}
                                    </div>
                                );
                            })}
                            <IconContext.Provider value={{ className: 'global-class-name', size: '32px' }}>
                                <a className="cell field-edit" href={row.pdf_link} target={'_blank'}>
                                    <FaRegFilePdf />
                                </a>
                                <div className="cell field-edit">
                                    <FiEdit onClick={() => editProspect(row)} />
                                </div>
                                <div className="cell field-archive">
                                    {isArchived ? <MdUnarchive onClick={() => archiveProspect(row)} /> : <FiArchive onClick={() => archiveProspect(row)} />}
                                </div>
                            </IconContext.Provider>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
