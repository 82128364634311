import axiosClient from 'api/client';
import { Quotation, QuotationFile } from 'components/Dashboard/useDashboardVm';

export type QuotationPayloadType = {
    abri: string;
    abri_type: string;
    adress: string;
    annee: string;
    city: string;
    created_at: string;
    email: string;
    file: QuotationFile;
    firstname: string;
    hauteur: string;
    infos: string;
    largeur: string;
    lastname: string;
    mois: string;
    pentes: string;
    permis: string;
    phone: string;
    pose: string;
    profondeur: string;
    zipcode: string;
    call_link: string;
    call_date: string;
    situation: string;
};

export const getProspects = async () => {
    return await axiosClient.get('/app/quotations');
};

export const getArchivedProspects = async () => {
    return await axiosClient.get(`/app/quotations/archives`);
}

export const setSituation = async (id: number, situation: string) => {
    return await axiosClient.post(`/app/quotation/${id}/situation`, {value: situation});
}

export const getProspect = async (id: number) => {
    return await axiosClient.get(`/app/quotation/${id}`);
};

export const createProspect = (data: { title: string; body: string; userId: number }) => {
    return axiosClient.post('/app/quotations', data).then((response) => response);
};

export const setProspectArchived = (id: number, value: boolean) => {
    return axiosClient.post(`/app/quotation/${id}/archive`, { value }).then((response) => response);
}


export const updateQuotation = async (data: {}, id: number) => {
    return await axiosClient.post(`/app/quotation/${id}`, data).then((response) => response);
};
export const validateQuotation = async (data: {}, id: number) => {
    return await axiosClient.post(`/app/quotation/${id}/validate`, data).then((response) => response);
};
