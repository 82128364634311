import React, { useState } from 'react';
import logo from 'assets/logos/logo-light.png';
import { LoginOrLogout } from '../LoginOrLogout/LoginOrLogout';
import './Sidebar.scss';
import { Link, useLocation } from 'react-router-dom';

export const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(true);

    const { pathname } = useLocation();

    function hideSideBar() {
        setIsOpen(false);
    }

    const links = [
        {
            url: '/dashboard',
            label: 'Prospects',
            active: ["dashboard", "prospect"]
        },
        {
            url: '/archives',
            label: 'Archives',
            active: ["archives"]
        }
    ];

    return (
        <aside className={`sidebar`}>
            {/*<div className="collapse" onClick={() => setIsOpen(!isOpen)}>*/}
            {/*    <div className="icon">*/}
            {/*        <span className="bar"></span>*/}
            {/*        <span className="bar"></span>*/}
            {/*        <span className="bar"></span>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <img src={logo} alt="Logo déco charpente" className="logo" />
            <menu>
                {
                    links.map((link, index) => {
                        return <li className={link.active.includes(pathname.split('/')[1]) ? 'active' : ''}>
                            <Link to={link.url} key={'navbar-link-' + index}
                                  onClick={hideSideBar}>{link.label}</Link>
                        </li>;
                    })
                }
            </menu>
            <LoginOrLogout />
        </aside>
    );
};
