import { useDispatch, useSelector } from 'react-redux';
import { getIsLogged,  logout } from 'features/auth';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useLoginOrLogoutVm = () => {
    const isLogged = useSelector(getIsLogged);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleLogin = useCallback(() => {
        dispatch(logout());
        navigate('/');
    }, [dispatch, navigate]);

    return {
        isLogged,
        toggleLogin
    };
};
