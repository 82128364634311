import { createSlice } from '@reduxjs/toolkit';
import { Quotation } from 'components/Dashboard/useDashboardVm';
import { RootState } from 'features';

export type User = {
    connected_at: string;
    created_at: string;
    email: string;
    firstname: string;
    fullname: string;
    id: number;
    lastname: string;
    online: boolean;
    role: string;
};

export type ProjetClient = {
    pergola: 'contemporaine' | 'traditionnelle' | '',
    essence_bois: 'épicéa' | 'douglas avec aubier' | 'douglas hors aubier' | '',
    traitement_par_situation: 'brun' | 'gris' | '',
    finition_liens: 'droits' | 'incurvés' | '',
    hauteur_sous_sabliere: number | '';
    nature_couverture: 'pst' | 'tuiles canal' | 'tuiles mécanique' | 'étanchéité' | 'autres' | '',
    bardage_bois: 'simple peau' | 'double peau' | '',
    bardage_essence: 'Épicéa' | 'douglas' | '',
    garde_corps: 'À croix de St-André' | 'balustre droit' | '',
    garde_corps_essence: 'épicea' | 'douglas' | '',
    terrasse_bois: number | '';
}

export type TravauxProgrammes = {
    plots: ChoixCharge,
    dalle_en_beton: ChoixCharge,
    demontage_existant: ChoixCharge,
    decaissement_du_terrain: ChoixCharge,
    elevation_de_murets: ChoixCharge,
    coupe_vegetale: ChoixCharge,
    photos: string,
}

type ChoixCharge = 'charge client' | 'charge dc' | ''

export type Questions = {
    permis: string;
    date: string;
    priorite: string;
    etude: string;
    installation: string;
    decouverte: string;
    aide: string;
    budget: string;
    raison: string;
    technique: string;
}

export type EditingProspect = {
    infos: Quotation,
    questionary: Questions,
    projetClient: ProjetClient,
    travaux: TravauxProgrammes,
}

export type ProspectsState = EditingProspect;

const initialProspectsState = {
    infos: {},
    questionary: <Questions>{},
    projetClient: <ProjetClient>{},
    travaux: <TravauxProgrammes>{},
};

export const prospectsSlice = createSlice({
    name: 'decoCharpenteProspect',
    initialState: initialProspectsState as unknown as ProspectsState,
    reducers: {
        setEditingProspectInfos: (state, data) => {
            state.infos = data.payload;
            return state;
        },
        setEditingProspect: (state, data) => {
            state = data.payload;
            return state;
        },
        selectEditingProspect: (state, data) => {
            return {
                ...state
            };
        }
    }
});

export const { setEditingProspectInfos, selectEditingProspect, setEditingProspect } = prospectsSlice.actions;

export const getCurrentlyEditing = (state: RootState) => state.decoCharpenteProspect;

export default prospectsSlice.reducer;
