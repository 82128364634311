import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getProspect } from 'api/prospects';
import { Quotation } from 'components/Dashboard/useDashboardVm';
import {
    setEditingProspectInfos,
    setEditingProspect,
    EditingProspect,
    Questions, ProjetClient, TravauxProgrammes
} from 'features/prospects';
import { store } from 'features/index';
import { useLocation } from 'react-router-dom';


export const useProspectViewVm = (id: string | undefined, mode: string) => {
    const [prospect, setProspect] = useState<EditingProspect>();

    const dispatch = useDispatch();

    useEffect(() => {
    }, [mode]);

    useEffect(() => {
        if (!id || mode == 'recap') return;
        let focusedProspect = store.getState().decoCharpenteProspect;

        getProspect(+id).then(({ data }) => {
            let editingProspect = {
                infos: {
                    ...data,
                    date: data?.annee + '-' + data?.mois
                },
                questionary: data.questionary,
                projetClient: data.projet_client,
                travaux: data.travaux
            };
            dispatch(setEditingProspect(editingProspect));
            setProspect(editingProspect);
        });

    }, [id, setProspect, dispatch]);

    const initialValues = {
        infos: {
            ...prospect?.infos as Quotation,
            date: prospect?.infos.annee + '-' + prospect?.infos.mois?.padStart(2, '0')
        },
        questionary: prospect?.questionary ?? <Questions>{},
        projetClient: prospect?.projetClient ?? <ProjetClient>{},
        travaux: prospect?.travaux ?? <TravauxProgrammes>{
            photos: "À nous envoyer (minimum 2)"
        }
    }

    const [error, setError] = useState<string>('');

    const formik = useFormik<EditingProspect>({
        initialValues,
        enableReinitialize: true,

        onSubmit: (prospect: EditingProspect) => {
            setError('');
            let projetClient: ProjetClient, travaux: TravauxProgrammes, questionary: Questions, infos;
            ({ projetClient, travaux, questionary, ...infos } = prospect);
            const newObj = {
                infos: {
                    ...infos.infos,
                    mois: prospect.infos?.date?.substring(5, 7),
                    annee: prospect.infos?.date?.substring(0, 4)
                },
                projetClient,
                travaux: {
                    ...travaux,
                    photos: 'À nous envoyer (minimum 2)'
                },
                questionary
            };
            dispatch(setEditingProspect(newObj));
            setProspect(newObj);
        }
    });

    return { prospect, formik, error, initialValues };
};