import { useEffect, useState } from 'react';
import { getIsLogged } from 'features/auth';
import { useSelector } from 'react-redux';
import { getArchivedProspects, getProspects } from 'api/prospects';
import { ProjetClient, Questions, TravauxProgrammes } from 'features/prospects';

export type QuotationFile = {
    id: number;
    filename: string;
    created_at: string;
    mime_type: string;
    online: boolean;
    original_filename: string;
    size: string;
    token: string;
    view_link: string;
}

export type Quotation = {
    abri: string;
    abri_type: string;
    adress: string;
    annee: string;
    archived: boolean;
    city: string;
    created_at: string;
    email: string;
    file: QuotationFile;
    firstname: string;
    hauteur: string;
    id: number;
    infos: string;
    largeur: string;
    lastname: string;
    mois: string;
    pentes: string;
    permis: string;
    phone: string;
    pose: string;
    profondeur: string;
    zipcode: string;
    date: string;
    call_date: string;
    call_link: string;
    situation: string;
    questionary: Questions;
    projetClient: ProjetClient;
    travaux: TravauxProgrammes;
    pdf_link?: string;
};

// This custom hook ends with `Vm` which means it's associated to a specific view
// So it cannot be used  by anything else than it's associated view
export const useDashboardVm = ({ archive }: { archive: boolean }) => {
    const [quotations, setQuotations] = useState<Quotation[] | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [refresh, setRefresh] = useState(false);

    const isLogged = useSelector(getIsLogged);

    useEffect(() => {
        if (!isLogged) return;

        if (!archive) {
            getProspects()
                .then(({ data }) => {
                    const prospects = data
                    prospects.sort((a: Quotation, b: Quotation) => (b.id - a.id));
                    setQuotations(prospects);
                })
                .catch((e) => {
                    setError('Une erreur est survenue...');
                });
        } else {
            getArchivedProspects()
                .then(({ data }) => {
                    const prospects = data.sort((a: Quotation, b: Quotation) => (b.id - a.id));
                    setQuotations(prospects);
                })
                .catch((e) => {
                    setError('Une erreur est survenue...');
                });
        }

    }, [isLogged, archive, refresh]);

    return {
        quotations,
        error,
        isLogged,
        setRefresh
    };
};

