import axios from 'axios';
import { store } from 'features/index';

const axiosClient = axios.create();

axiosClient.defaults.baseURL = process.env.REACT_APP_API_URL;
axiosClient.defaults.timeout = 20000;

axiosClient.interceptors.request.use(
    async (config) => {
        config.headers.jwt = store.getState().decoCharpenteAuthentication.jwt;
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

export default axiosClient;
