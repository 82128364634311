import React from 'react';
import { Link, useParams } from 'react-router-dom';
import arrow from 'assets/images/icons/arrow-left.svg';
import './ProspectView.scss';
import { ProspectComponent } from '../../components/ProspectComponent/ProspectComponent';
import { useProspectViewVm } from './useProspectViewVm';
import ProspectRecap from 'components/ProspectRecap/ProspectRecap';

type Props = {
    mode: string;
};

export default function ProspectView({ mode }: Props) {
    let { id } = useParams();
    const { prospect, formik, initialValues } = useProspectViewVm(id, mode);

    return (
        <div className={`prospect-view ${mode}-prospect-view`}>
            <div className="heading">
                <Link to={'..'}>
                    <img src={arrow} alt="" />
                </Link>
                <h2>
                    {mode === 'recap' && 'Récapitulatif de'} {prospect?.infos.firstname} {prospect?.infos.lastname}
                </h2>
            </div>
            {mode === 'edit' && <ProspectComponent prospect={prospect} formik={formik} formikInitialValues={initialValues} />}
            {mode === 'recap' && prospect && <ProspectRecap prospect={prospect} />}
        </div>
    );
}
