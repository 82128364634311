import axiosClient from 'api/client';
import { User } from 'features/auth';

export type LoginPayloadType = {
    email: string;
    password: string;
};



export type LoginResponse = {
    jwt: string;
    user: User;
};

export const login = async (data: LoginPayloadType) => {
    return await axiosClient.post('/app/auth/login', data);
};