import React, { FormEvent, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import './ProspectComponent.scss';
import { DateTime } from 'luxon';
import { FaEye } from 'react-icons/fa';
import { EditingProspect } from 'features/prospects';
import { Formik, FormikValues, useFormikContext } from 'formik';
import { setProspectArchived, updateQuotation } from 'api/prospects';
import Swal from 'sweetalert2';

type Props = React.PropsWithChildren & {
    id?: string;
    prospect?: EditingProspect;
    formik: any;
    formikInitialValues?: FormikValues & EditingProspect;
};
export const ProspectComponent = ({ id, prospect, formik, formikInitialValues }: Props) => {
    const navigate = useNavigate();
    const [preciseDimensions, setPreciseDimensions] = useState(false);
    const isPergola = formik.values.infos.abri === 'Pergola';
    const [isSaved, setIsSaved] = useState(false);

    function confirmExit() {
        return "Les modifications que vous avez apportées ne seront peut-être pas enregistrées.";
    }

    useEffect(() => {
        window.scrollTo(0, 0);

    }, []);

    useEffect(() => {
        if(isSaved) return;
        const handleBeforeUnload = (event: any) => {
            event.preventDefault();
            event.returnValue = 'Test';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isSaved]);

    function saveProspect() {
        const {
            infos: prospect,
            questionary,
            projetClient,
            travaux
        } = formik.values;

        updateQuotation({
            ...prospect,
            call_date: prospect.call_date ? prospect.call_date.substring(0, 16) : null,
            questionary,
            travaux,
            projetClient
        }, prospect.id).then((r) => {
            if (r.status != 200) return;
        });
        Swal.fire({
            title: `Modifications enregistrées`,
            text: `Les informations du prospect ${prospect.firstname} ${prospect.lastname} ont bien été enregistrées!`,
            icon: 'success'
        }).then(() => {
            setIsSaved(true);
        });
    }


    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="prospect-component">
                <div className="main">
                    {formik && (
                        <div className={'form'}>
                            <section className={'step-1'}>
                                <div className="title">
                                    <div className="step"></div>
                                    Choix de l'abri
                                </div>
                                <div className="inputs">
                                    <label>
                                        Abri recherché :{' '}
                                        <select id="infos.abri" name="infos.abri" value={formik.values.infos.abri}
                                                onChange={formik.handleChange}>
                                            <option value="" hidden>Je ne sais pas</option>
                                            <option value="Je ne sais pas">Je ne sais pas</option>
                                            <option value="Appentis & Auvent">Appentis & Auvent</option>
                                            <option value="Pergola">Pergola</option>
                                            <option value="Pool house">Pool house</option>
                                            <option value="Abri voiture">Abri voiture</option>
                                            <option value="Abri collectif">Abri collectif</option>
                                            <option value="Solution décorative">Solution décorative</option>
                                            <option value="Autre">Autre</option>
                                        </select>
                                    </label>
                                </div>
                            </section>

                            <section className={'step-2'}>
                                <div className="title">
                                    <div className="step"></div>
                                    Type d'abri
                                </div>
                                <div className="inputs">
                                    <label>
                                        Abri recherché :{' '}
                                        <select id="infos.abri_type" name="infos.abri_type"
                                                value={formik.values.infos.abri_type} onChange={formik.handleChange}>
                                            <option value="Je ne sais pas">Je ne sais pas</option>
                                            <option value="Autoporté" hidden={isPergola}>Autoporté</option>
                                            <option value="Adossé" hidden={isPergola}>Adossé</option>
                                            <option value="Autoportée" hidden={!isPergola}>Autoportée</option>
                                            <option value="Adossée" hidden={!isPergola}>Adossée</option>

                                        </select>
                                    </label>
                                    {!isPergola && <label>
                                        Nombre de pentes souhaité :{' '}
                                        <input
                                            id="infos.pentes"
                                            name="infos.pentes"
                                            type="text"
                                            placeholder="Pentes"
                                            onChange={formik.handleChange}
                                            value={formik.values.infos.pentes}
                                        />
                                    </label>}
                                </div>
                            </section>

                            <section className={'step-3'}>
                                <div className="title">
                                    <div className="step"></div>
                                    Dimensions de l'abri
                                </div>
                                <div className="inputs">
                                    <label>
                                        Hauteur :{' '}
                                        <span>{prospect?.infos.hauteur ? prospect?.infos.hauteur : 'Aucune'}</span>
                                    </label>
                                    <label>
                                        Largeur :{' '}
                                        <span>{prospect?.infos.largeur ? prospect?.infos.largeur : 'Aucune'}</span>
                                    </label>
                                    <label>
                                        Profondeur :
                                        <span>{prospect?.infos.profondeur ? prospect?.infos.profondeur : 'Aucune'}</span>
                                    </label>
                                    {preciseDimensions && <>
                                        <label>
                                            Hauteur :{' '}
                                            <input
                                                id="infos.hauteur"
                                                name="infos.hauteur"
                                                type="number"
                                                placeholder="Hauteur"
                                                onChange={formik.handleChange}
                                                value={formik.values.infos.hauteur}
                                            />
                                        </label>
                                        <label>
                                            Largeur :{' '}
                                            <input
                                                id="infos.largeur"
                                                name="infos.largeur"
                                                type="number"
                                                placeholder="Largeur"
                                                onChange={formik.handleChange}
                                                value={formik.values.infos.largeur}
                                            />
                                        </label>
                                        <label>
                                            Profondeur :{' '}
                                            <input
                                                id="infos.profondeur"
                                                name="infos.profondeur"
                                                type="number"
                                                placeholder="Profondeur"
                                                onChange={formik.handleChange}
                                                value={formik.values.infos.profondeur}
                                            />
                                        </label>
                                    </>}
                                    {!preciseDimensions &&
                                        <button onClick={() => setPreciseDimensions(true)}>Préciser les
                                            dimensions</button>}
                                </div>
                            </section>

                            <section className={'step-4'}>
                                <div className="title">
                                    <div className="step"></div>
                                    Date et pose
                                </div>
                                <div className="inputs">
                                    <label>
                                        Date :{' '}
                                        <input
                                            id="infos.date"
                                            name="infos.date"
                                            type="month"
                                            placeholder="Type d'abris"
                                            value={formik.values.infos.date}
                                            onChange={formik.handleChange}
                                        />
                                    </label>
                                    <label>
                                        Demande de pose :{' '}
                                        <select
                                            name="infos.pose"
                                            id="infos.pose"
                                            onChange={formik.handleChange}
                                            value={formik.values.infos.pose}
                                        >
                                            <option value="Je ne sais pas">Je ne sais pas</option>
                                            <option value="Non">Non</option>
                                            <option value="Deco Charpente">Oui</option>
                                        </select>
                                    </label>
                                </div>
                            </section>

                            <section className={'step-5'}>
                                <div className="title">
                                    <div className="step"></div>
                                    Détails du projet
                                </div>
                                <div className="inputs">
                                    <label>
                                        Permis de la mairie :{' '}
                                        <select
                                            name="infos.permis"
                                            id="infos.permis"
                                            onChange={formik.handleChange}
                                            value={formik.values.infos.permis}
                                        >
                                            <option value="je ne sais pas">Je ne sais pas</option>
                                            <option value="Non">Non</option>
                                            <option value="Oui">Oui</option>
                                        </select>
                                    </label>
                                    <label>
                                        Note supplémentaire :
                                        <textarea
                                            name="infos.infos"
                                            id="infos.infos"
                                            cols={30}
                                            rows={6}
                                            maxLength={2000}
                                            onChange={formik.handleChange}
                                            value={formik.values.infos.infos}
                                        ></textarea>
                                        <Link to={prospect?.infos.file?.view_link ?? ''} target={'_blank'}
                                              className={'button ' + (prospect?.infos.file ? '' : 'disabled')}>
                                            {prospect?.infos.file ? <><FaEye /> Voir le
                                                fichier</> : 'Aucun fichier joint'}
                                        </Link>
                                    </label>

                                </div>
                            </section>

                            <section className={'step-6'}>
                                <div className="title">
                                    <div className="step"></div>
                                    Coordonées
                                </div>
                                <div className="inputs">
                                    <label htmlFor="lastname">
                                        Nom :{' '}
                                        <input
                                            id="infos.lastname"
                                            name="infos.lastname"
                                            type="text"
                                            placeholder="Nom de famille"
                                            onChange={formik.handleChange}
                                            value={formik.values.infos.lastname}
                                        />
                                    </label>
                                    <label htmlFor="firstname">
                                        Prénom :{' '}
                                        <input
                                            id="infos.firstname"
                                            name="infos.firstname"
                                            type="text"
                                            placeholder="Prénom"
                                            onChange={formik.handleChange}
                                            value={formik.values.infos.firstname}
                                        />
                                    </label>
                                    <label htmlFor="email">
                                        E-mail :{' '}
                                        <input
                                            id="infos.email"
                                            name="infos.email"
                                            type="email"
                                            placeholder="Adresse email"
                                            onChange={formik.handleChange}
                                            value={formik.values.infos.email}
                                        />
                                    </label>
                                    <label htmlFor="lastname">
                                        Téléphone :{' '}
                                        <input
                                            id="infos.phone"
                                            name="infos.phone"
                                            type="phone"
                                            placeholder="Téléphone"
                                            onChange={formik.handleChange}
                                            value={formik.values.infos.phone}
                                        />
                                    </label>
                                    <label htmlFor="adress">
                                        Adresse :{' '}
                                        <input
                                            id="infos.adress"
                                            name="infos.adress"
                                            type="text"
                                            placeholder="Adresse"
                                            onChange={formik.handleChange}
                                            value={formik.values.infos.adress}
                                        />
                                    </label>
                                    <label htmlFor="lastname">
                                        Code Postal :{' '}
                                        <input
                                            id="infos.zipcode"
                                            name="infos.zipcode"
                                            type="text"
                                            placeholder="Code postal"
                                            onChange={formik.handleChange}
                                            value={formik.values.infos.zipcode}
                                        />
                                    </label>
                                    <label htmlFor="lastname">
                                        Ville :{' '}
                                        <input
                                            id="infos.city"
                                            name="infos.city"
                                            type="text"
                                            placeholder="Ville"
                                            onChange={formik.handleChange}
                                            value={formik.values.infos.city}
                                        />
                                    </label>
                                </div>
                            </section>
                        </div>
                    )}

                    <div className="questionnaire">
                        <h3>Questionnaire</h3>
                        <section>
                            <div className="title">
                                Avez-vous déjà soumis une demande à la mairie concernant votre projet (permis de
                                construire, déclaration
                                préalable de travaux) ?
                            </div>
                            <textarea
                                name="questionary.permis"
                                id="questionary.permis"
                                cols={30}
                                rows={4}
                                onChange={formik.handleChange}
                                value={formik.values.questionary?.permis}
                                placeholder={'Votre réponse...'}
                            ></textarea>
                        </section>
                        <section>
                            <div className="title">Quand prévoyez-vous de commencer votre projet ?</div>
                            <textarea
                                name="questionary.date"
                                id="questionary.date"
                                cols={30}
                                rows={4}
                                placeholder={'Votre réponse...'}
                                onChange={formik.handleChange}
                                value={formik.values.questionary?.date}
                            ></textarea>
                        </section>
                        <section>
                            <div className="title">
                                Y a-t-il d’autres projets que vous envisagez qui pourraient être prioritaires par
                                rapport à celui-ci ?
                            </div>
                            <textarea
                                name="questionary.priorite"
                                id="questionary.priorite"
                                cols={30}
                                rows={4}
                                placeholder={'Votre réponse...'}
                                onChange={formik.handleChange}
                                value={formik.values.questionary?.priorite}
                            ></textarea>
                        </section>
                        <section>
                            <div className="title">Avons-nous déjà effectué une étude préliminaire de votre projet ?
                            </div>
                            <textarea
                                name="questionary.etude"
                                id="questionary.etude"
                                cols={30}
                                rows={4}
                                placeholder={'Votre réponse...'}
                                onChange={formik.handleChange}
                                value={formik.values.questionary?.etude}
                            ></textarea>
                        </section>
                        <section>
                            <div className="title">
                                Que recherchez vous ? Une prestation avec ou sans pose ?
                            </div>
                            <textarea
                                name="questionary.installation"
                                id="questionary.installation"
                                cols={30}
                                rows={4}
                                placeholder={'Votre réponse...'}
                                onChange={formik.handleChange}
                                value={formik.values.questionary?.installation}
                            ></textarea>
                        </section>
                        <section>
                            <div className="title">Comment avez vous connu Déco Charpente ?</div>
                            <textarea
                                name="questionary.decouverte"
                                id="questionary.decouverte"
                                cols={30}
                                rows={4}
                                placeholder={'Votre réponse...'}
                                onChange={formik.handleChange}
                                value={formik.values.questionary?.decouverte}
                            ></textarea>
                        </section>
                        <section>
                            <div className="title">Comptez-vous bénéficier d’une aide financière pour ce projet ?</div>
                            <textarea
                                name="questionary.aide"
                                id="questionary.aide"
                                cols={30}
                                rows={4}
                                placeholder={'Votre réponse...'}
                                onChange={formik.handleChange}
                                value={formik.values.questionary?.aide}
                            ></textarea>
                        </section>
                        <section>
                            <div className="title">Quel est le budget que vous avez prévu pour ce projet ?</div>
                            <textarea
                                name="questionary.budget"
                                id="questionary.budget"
                                cols={30}
                                rows={4}
                                placeholder={'Votre réponse...'}
                                onChange={formik.handleChange}
                                value={formik.values.questionary?.budget}
                            ></textarea>
                        </section>
                        <section>
                            <div className="title">Quelle est la raison principale pour laquelle vous souhaitez
                                construire cet abri ?
                            </div>
                            <textarea
                                name="questionary.raison"
                                id="questionary.raison"
                                cols={30}
                                rows={4}
                                placeholder={'Votre réponse...'}
                                onChange={formik.handleChange}
                                value={formik.values.questionary?.raison}
                            ></textarea>
                        </section>

                        <div className={'technical-sheet'}>
                            <h3>Projet du client</h3>
                            <div className="inputs">
                                <label>
                                    Pergola:{' '}
                                    <select
                                        name="projetClient.pergola"
                                        value={formik.values.projetClient?.pergola}
                                        onChange={formik.handleChange}>
                                        <option value="">Choisir une option</option>
                                        <option value="contemporaine">Contemporaine</option>
                                        <option value="traditionnelle">Traditionnelle</option>
                                    </select>
                                </label>
                                <label>
                                    Essence de bois:{' '}
                                    <select
                                        name="projetClient.essence_bois"
                                        value={formik.values.projetClient?.essence_bois}
                                        onChange={formik.handleChange}>
                                        <option value="">Choisir une option</option>
                                        <option value="epicea">Épicéa</option>
                                        <option value="douglas avec aubier">Douglas avec aubier</option>
                                        <option value="douglas hors aubier">Douglas hors aubier</option>
                                    </select>
                                </label>
                                <label>
                                    Traitement de bois:{' '}
                                    <select
                                        name="projetClient.traitement_par_situation"
                                        value={formik.values.projetClient?.traitement_par_situation}
                                        onChange={formik.handleChange}>
                                        <option value="">Choisir une option</option>
                                        <option value="brun">Brun</option>
                                        <option value="gris">Gris</option>
                                    </select>
                                </label>
                                <label>
                                    Finition liens et contrefiche:{' '}
                                    <select
                                        name="projetClient.finition_liens"
                                        value={formik.values.projetClient?.finition_liens}
                                        onChange={formik.handleChange}>
                                        <option value="">Choisir une option</option>
                                        <option value="droits">Droits</option>
                                        <option value="incuves">Incurvés</option>
                                    </select>
                                </label>
                                <label>
                                    Hauteur sous sablière (ml):{' '}
                                    <input type={'number'}
                                           name="projetClient.hauteur_sous_sabliere"
                                           value={formik.values.projetClient?.hauteur_sous_sabliere}
                                           onChange={formik.handleChange} />
                                </label>
                                <label>
                                    Nature de la couverture:{' '}
                                    <select
                                        name="projetClient.nature_couverture"
                                        value={formik.values.projetClient?.nature_couverture}
                                        onChange={formik.handleChange}>
                                        <option value="">Choisir une option</option>
                                        <option value="pst">PST</option>
                                        <option value="tuiles canal">Tuiles canal</option>
                                        <option value="tuiles mécanique">Tuiles mécanique</option>
                                        <option value="étanchéité">Étanchéité</option>
                                        <option value="autres">Autres</option>
                                    </select>
                                </label>
                                <label>
                                    Bardage bois:{' '}
                                    <select
                                        name="projetClient.bardage_bois"
                                        value={formik.values.projetClient?.bardage_bois}
                                        onChange={formik.handleChange}>
                                        <option value="">Choisir une option</option>
                                        <option value="simple peau">Simple peau</option>
                                        <option value="double peau">Double peau</option>
                                    </select>
                                </label>
                                <label>
                                    Bardage essence:{' '}
                                    <select
                                        name="projetClient.bardage_essence"
                                        value={formik.values.projetClient?.bardage_essence}
                                        onChange={formik.handleChange}>
                                        <option value="">Choisir une option</option>
                                        <option value="epicea">Épicéa</option>
                                        <option value="douglas">Douglas</option>
                                    </select>
                                </label>
                                <label>
                                    Garde corps:{' '}
                                    <select
                                        name="projetClient.garde_corps"
                                        value={formik.values.projetClient?.garde_corps}
                                        onChange={formik.handleChange}>
                                        <option value="">Choisir une option</option>
                                        <option value="À croix st-andre">À croix St-André</option>
                                        <option value="balustre droit">Balustre droit</option>
                                    </select>
                                </label>
                                <label>
                                    Garde corps essence:{' '}
                                    <select
                                        name="projetClient.garde_corps_essence"
                                        value={formik.values.projetClient?.garde_corps_essence}
                                        onChange={formik.handleChange}>
                                        <option value="">Choisir une option</option>
                                        <option value="Épicéa">Épicéa</option>
                                        <option value="Douglas">Douglas</option>
                                    </select>
                                </label>
                                <label>
                                    Terrasse bois:{' '}
                                    <select
                                        name="projetClient.terrasse_bois"
                                        value={formik.values.projetClient?.terrasse_bois}
                                        onChange={formik.handleChange}>
                                        <option value="">Choisir une option</option>
                                        <option value="oui">Oui</option>
                                        <option value="non">Non</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <div className={'technical-sheet'}>
                            <h3>Travaux préparatoires</h3>
                            <div className="inputs">
                                <label>
                                    Plots en béton:{' '}
                                    <select
                                        name="travaux.plots"
                                        value={formik.values.travaux?.plots}
                                        onChange={formik.handleChange}>
                                        <option value="">Choisir une option</option>
                                        <option value="charge client">À charge client</option>
                                        <option value="charge dc">À charge Déco Charpente</option>
                                    </select>
                                </label>
                                <label>
                                    Dalle en béton:{' '}
                                    <select
                                        name="travaux.dalle_en_beton"
                                        value={formik.values.travaux?.dalle_en_beton}
                                        onChange={formik.handleChange}>
                                        <option value="">Choisir une option</option>
                                        <option value="charge client">À charge client</option>
                                        <option value="charge dc">À charge Déco Charpente</option>
                                    </select>
                                </label>
                                <label>
                                    Démontage existant:{' '}
                                    <select
                                        name="travaux.demontage_existant"
                                        value={formik.values.travaux?.demontage_existant}
                                        onChange={formik.handleChange}>
                                        <option value="">Choisir une option</option>
                                        <option value="charge client">À charge client</option>
                                        <option value="charge dc">À charge Déco Charpente</option>
                                    </select>
                                </label>
                                <label>
                                    Décaissement du terrain:{' '}
                                    <select
                                        name="travaux.decaissement_du_terrain"
                                        value={formik.values.travaux?.decaissement_du_terrain}
                                        onChange={formik.handleChange}>
                                        <option value="">Choisir une option</option>
                                        <option value="charge client">À charge client</option>
                                        <option value="charge dc">À charge Déco Charpente</option>
                                    </select>
                                </label>
                                <label>
                                    Élévation de murets:{' '}
                                    <select
                                        name="travaux.elevation_de_murets"
                                        value={formik.values.travaux?.elevation_de_murets}
                                        onChange={formik.handleChange}>
                                        <option value="">Choisir une option</option>
                                        <option value="charge client">À charge client</option>
                                        <option value="charge dc">À charge Déco Charpente</option>
                                    </select>
                                </label>
                                <label>
                                    Coupe végétale:{' '}
                                    <select
                                        name="travaux.coupe_vegetale"
                                        value={formik.values.travaux?.coupe_vegetale}
                                        onChange={formik.handleChange}>
                                        <option value="">Choisir une option</option>
                                        <option value="charge client">À charge client</option>
                                        <option value="charge dc">À charge Déco Charpente</option>
                                    </select>
                                </label>
                                <label>
                                    Photo de l’emplacement du projet:{' '}
                                    <select name="travaux.photos"
                                            value={formik.values.travaux?.photos}
                                            onChange={formik.handleChange}>
                                        <option value="À nous envoyer (minimum 2)" selected>À nous envoyer (minimum 2)
                                        </option>
                                    </select>
                                </label>
                            </div>
                        </div>


                        <div className="ctas">
                            <button
                                className="alt"
                                type={'submit'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    formik.handleSubmit();
                                    navigate('recap');
                                }}
                            >
                                Valider le prospect
                            </button>
                        </div>
                    </div>
                </div>

                {
                    formik && (
                        <aside className="prospect-infos">
                            <div className="postcode badge">{formik.values.infos?.zipcode?.slice(0, 2) || '00'}</div>
                            <div>
                                {formik.values.infos?.firstname} {formik.values.infos?.lastname}
                            </div>
                            <div>{formik.values.infos?.email}</div>
                            <div>{DateTime.fromISO(formik.values.infos?.created_at).toLocaleString()}</div>
                            <div>{formik.values.infos?.zipcode}</div>
                            <div>{formik.values.infos?.phone}</div>

                            <div className="visio">
                                <label htmlFor="infos.call_date">
                                    Rappel Visio
                                    <input type="datetime-local" name={'infos.call_date'}
                                           value={formik.values.infos?.call_date?.substring(0, 16)}
                                           onChange={formik.handleChange} />
                                </label>

                                <Link className={'button sec'} to={'https://meet.jit.si/deco-charpente'}
                                      target={'_blank'}>Joindre
                                    la visio</Link>
                                <button
                                    className={`alt`}
                                    type={'submit'}
                                    onClick={saveProspect}
                                >
                                    Enregistrer
                                </button>
                            </div>

                        </aside>
                    )}

            </div>
        </form>
    );
};
