import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import { store } from 'features';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Router basename="/">
            <Provider store={store}>
                    <App />
            </Provider>
        </Router>
    </React.StrictMode>
);

reportWebVitals();
