import React, { SyntheticEvent, useCallback, useEffect, useReducer, useState } from 'react';
import './Dashboard.scss';
import { Quotation, useDashboardVm } from './useDashboardVm';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { Table, TableColumn } from 'components/ui-component/Table/Table';
import { DateTime } from 'luxon';
import { RiUserSearchLine } from 'react-icons/ri';
import { IconContext } from 'react-icons';
import { setSituation } from 'api/prospects';

export type ComponentProps = {
    archive: boolean;
};

export const Dashboard: React.FC<ComponentProps> = ({ archive }) => {
    const { quotations: apiQuotations, error, isLogged, setRefresh } = useDashboardVm({ archive });
    const [quotations, setQuotations] = useState<Quotation[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [isQuotationShown] = useState(false);
    const [key, setKey] = useState(0);

    const keysToSearchIn = ['firstname', 'lastname', 'email'];

    useEffect(() => {
        window.scrollTo(0, 0);
        if (apiQuotations) setQuotations(apiQuotations);
    }, [apiQuotations, key]);

    const columns: TableColumn[] = [
        {
            field: 'situation',
            label: 'Situation',
            customElement: (value, row) => (
                <select name="" id="" value={value?.toLowerCase()}
                    onChange={async ({ target: { value: selectValue } }) => {
                        let chosenQuotation = quotations.find((quot) => quot.id == row.id);
                        if (chosenQuotation) chosenQuotation.situation = selectValue;
                        await setSituation(row.id, selectValue);
                        window.location.reload();
                    }}>
                    <option value="prospect crée" selected>Prospect créé</option>
                    <option value="mail envoyé">Mail envoyé</option>
                </select>
            )
        },
        {
            field: 'zipcode',
            label: 'Code postal',
            format: (value) => {
                return value;
            },
            default: '00'
        },
        { field: 'firstname', label: 'Prénom Nom', customElement: (value, row) => <>{value} {row.lastname}</> },
        { field: 'email', label: 'Email' },
        {
            field: 'created_at',
            label: 'Date',
            format: (value) => {
                return DateTime.fromISO(value).toLocaleString();
            }
        }
        // { field: 'zipcode', label: 'Code postal', default: '00000' }
    ];

    function searchUser({ nativeEvent: { target } }: SyntheticEvent) {
        if (!target) return;
        setSearchText((target as HTMLInputElement)?.value.toLowerCase());
    }

    return (
        <div className="dashboard">
            {isLogged && (
                <>
                    <div className="head">
                        <h3>{archive ? 'Archives' : 'Prospects'} {quotations?.length ? (<>({quotations.length})</>) : <></>}</h3>
                        <div className="searchbar">
                            <IconContext.Provider value={{ className: 'icon', size: '24px' }}>
                                <RiUserSearchLine />
                            </IconContext.Provider>
                            <input type="text"
                                placeholder="Chercher un prospect"
                                onInput={searchUser} />
                        </div>
                    </div>

                    {quotations && (
                        <Table rows={quotations.filter((prospect) => {
                            return keysToSearchIn.some((field: string) => {
                                return (prospect[field as keyof Quotation] as string)?.toLowerCase().includes(searchText);
                            });
                        })} columns={columns} />
                    )}

                    {error && <p>Erreur : {error}</p>}
                </>
            )}
        </div>
    );
};
