import AuthentifiedLayout from 'layout/AuthentifiedLayout';
import UnAuthentifiedLayout from 'layout/UnAuthentifiedLayout';

import { Navigate } from 'react-router-dom';
import { DefaultView } from 'views/defaultView/DefaultView';
import { UnAuthenticatedView } from 'views/unAuthenticatedView/UnAuthenticatedView';
import ProspectView from 'views/ProspectView/ProspectView';

export const routes = (isLogged: boolean | null | undefined) => {
    return [
        {
            path: '/',
            element: <Navigate to="/loggedout" />
        },
        {
            path: '/loggedout',
            element: !isLogged ? <UnAuthentifiedLayout /> : <Navigate to="/dashboard" />,
            children: [
                {
                    path: '',
                    element: <UnAuthenticatedView />
                }
            ]
        },
        {
            path: '/dashboard',
            element: isLogged ? <AuthentifiedLayout /> : <Navigate to="/loggedout" />,
            children: [
                {
                    path: '',
                    element: <DefaultView archive={false} />
                }
            ]
        },
        {
            path: '/archives',
            element: isLogged ? <AuthentifiedLayout /> : <Navigate to="/loggedout" />,
            children: [
                {
                    path: '',
                    element: <DefaultView archive={true} />
                }
            ]
        },
        {
            path: '/prospect/:id',
            element: isLogged ? <AuthentifiedLayout /> : <Navigate to="/loggedout" />,
            children: [
                {
                    path: '',
                    element: <ProspectView mode={"edit"} />
                },
                {
                    path: '/prospect/:id/recap',
                    element: <ProspectView mode={"recap"} />
                }
            ]
        }
    ];
};

export default routes;
