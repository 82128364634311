import { Sidebar } from 'components/index';
import { Outlet } from 'react-router-dom';

const AuthentifiedLayout = () => (
    <>
        <Sidebar />
        <Outlet />
    </>
);

export default AuthentifiedLayout;
