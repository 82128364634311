import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'features';

export type User = {
    connected_at: string;
    created_at: string;
    email: string;
    firstname: string;
    fullname: string;
    id: number;
    lastname: string;
    online: boolean;
    role: string;
};

export type AuthState = {
    isLogged: boolean;
    user: User | null;
    jwt: string | null;
};

const initialAuthState = {
    isLogged: false,
    user: null,
    jwt: null
};

export const authSlice = createSlice({
    name: 'decoCharpenteAuthentication',
    initialState: initialAuthState,
    reducers: {
        login: (state, data) => {
            const { jwt, user } = data.payload;
            state.isLogged = true;
            state.jwt = jwt;
            state.user = user;
        },
        logout: (state) => {
            state.isLogged = false;
        }
    }
});
export const getIsLogged = (state: RootState) => state.decoCharpenteAuthentication.isLogged;
export const getUser = (state: RootState) => state.decoCharpenteAuthentication.user;
export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
